import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Delete from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@material-ui/core/styles";
import { GroupSelect, IOSSwitch, SelectWrapper, useStyles } from "./styles";
import { useGroupAPI } from "pages/PhotoAdmin/SelectShop/hooks/useGroupAPI";
import { useGetQueryString } from "hooks/useGetQueryString";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import { amber } from "@mui/material/colors";

const GroupItem = ({
  shopId,
  disabled,
  groups,
  group,
  isSchool,
  selectGroups,
  selected,
  putGroup,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  const {
    fetchCover,
    cover,
    coverLoading,
    deleteGroup,
    fetchProducts,
    products,
    productsLength,
    fetchInnerGroups,
    innerGroups,
  } = useGroupAPI();

  const [active, setActive] = useState(group.isActive);

  const [hide, setHide] = useState(false);
  const [alert, setAlert] = useState(false);
  const [recursiveAlert, setRecursiveAlert] = useState(false);
  const [count, setCount] = useState(undefined);

  // If true, changes made to the group is applied to its descendant groups, recursively
  const [inheritHeader, setinheritHeader] = useState(false);

  const { currentPage, pageSize, getProductQueryString } = useGetQueryString();

  useEffect(() => {
    fetchCover({ id: group.id });
    if (isSchool) {
      fetchInnerGroups({ id: group.id });
    } else {
      fetchProducts({
        id: group.id,
        queryString: getProductQueryString({
          page: currentPage,
          size: pageSize,
          sort: "name",
        }),
      });
    }
  }, [currentPage, group.id, isSchool, pageSize]);

  useEffect(() => {
    if (isSchool) {
      if (innerGroups?.length !== undefined) {
        setCount(innerGroups?.length);
      }
    } else {
      if (productsLength !== null) {
        setCount(productsLength);
      }
      if (productsLength === null) {
        setCount(0);
      }
    }
  }, [products, innerGroups, productsLength, isSchool]);

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 20) {
      displayedName =
        name.slice(0, 9) + "..." + name.slice(name.length - 9, name.length);
    }

    return displayedName;
  };

  const switchAuth = () => {
    if (group.groups) {
      setRecursiveAlert(true);
    } else {
      switchChanged();
    }
  };

  const switchChanged = () => {
    setRecursiveAlert(false);
    var json = "";
    if (group.isActive) {
      json = JSON.stringify(group).replace(
        '"isActive":true',
        '"isActive":false'
      );
    } else {
      json = JSON.stringify(group).replace(
        '"isActive":false',
        '"isActive":true'
      );
    }
    let body = { ...JSON.parse(json) };
    if (body.inheritModifiers) {
      body.modifierTemplate = null;
    }
    delete body.selected;
    setActive(!active);
    putGroup({ id: group.id, body: body });
  };

  const routeChange = (id) => {
    let path = `/shops/${shopId}/administration/galleries/${id}`;
    navigate(path);
  };

  const handleDelete = async () => {
    deleteGroup({ id: group.id });
    setAlert(false);
    setHide(true);
  };

  return (
    <>
      {hide ? (
        <></>
      ) : (
        <Zoom in={true} timeout={1000}>
          <Card className={classes.cardStyle} component={Paper} count={count}>
            <SelectWrapper
              style={{
                display:
                  (isSchool ||
                    group.name === "Csoportképek" ||
                    group.name === "Group photos") &&
                  "none",
              }}
              onClick={() => selectGroups()}
            >
              <GroupSelect
                color="primary"
                type="checkbox"
                checked={selected}
                name=""
                id=""
              />
            </SelectWrapper>
            <IOSSwitch
              theme={theme}
              className={classes.switch}
              sx={{ m: 1 }}
              onChange={() => switchAuth()}
              checked={active}
            />
            {
              <Delete
                className={classes.delete}
                onClick={() => setAlert(true)}
              />
            }
            <CardActionArea
              style={{
                opacity: active ? "100%" : "50%",
                border:
                  count === 0
                    ? `4px solid ${theme.palette.primary.error}`
                    : group?.isPrinted
                    ? `4px solid ${amber[500]}`
                    : group?.isPrintingDone
                    ? `4px solid ${theme.palette.primary.success}`
                    : "4px solid transparent",
              }}
              className={classes.cardActionArea}
              onClick={() => {
                if (disabled) return;
                routeChange(group.id);
              }}
            >
              {coverLoading ? (
                <LoadIndicator></LoadIndicator>
              ) : (
                <CardMedia className={classes.media} image={cover}>
                  <div></div>
                </CardMedia>
              )}
              <Tooltip title={group.name}>
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.cardTitle} variant="body1">
                    {calcName(group.name)}
                  </Typography>
                  <div className={classes.validThru}>
                    {group?.productGroupModifierValidities?.find((el) =>
                      el.modifierUriSegment?.includes("paper")
                    ) ? (
                      <div className={classes.validThruInner}>
                        Nyomtatott:{" "}
                        {group?.productGroupModifierValidities?.map((el) => {
                          if (el.modifierUriSegment?.includes("paper")) {
                            return new Date() <
                              new Date(
                                el?.validUntil?.toString().substring(0, 10)
                              ) ? (
                              <div>
                                {el?.validUntil?.toString().substring(0, 10)}
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>Lejárt</div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <div className={classes.validThruInner}>
                        Nyomtatott:{" "}
                        {group?.productGroupModifierValidities?.map((el) => {
                          if (el.modifierUriSegment?.includes("id:21")) {
                            return new Date() <
                              new Date(
                                el?.validUntil?.toString().substring(0, 10)
                              ) ? (
                              <div>
                                {el?.validUntil?.toString().substring(0, 10)}
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>Lejárt</div>
                            );
                          }
                        })}
                      </div>
                    )}

                    <div className={classes.validThruInner}>
                      Digitális:{" "}
                      {group?.productGroupModifierValidities?.map((el) => {
                        if (el?.modifierUriSegment?.includes("email")) {
                          return new Date() <
                            new Date(
                              el?.validUntil?.toString().substring(0, 10)
                            ) ? (
                            <div>
                              {el?.validUntil?.toString().substring(0, 10)}
                            </div>
                          ) : (
                            <div style={{ color: "red" }}>Lejárt</div>
                          );
                        }
                      })}
                    </div>
                    {group?.productGroupModifierValidities?.find((el) =>
                      el.modifierUriSegment?.includes("lelkesprint")
                    ) && (
                      <div className={classes.validThruInner}>
                        Ajándéktárgy:{" "}
                        {group?.productGroupModifierValidities?.map((el) => {
                          if (el?.modifierUriSegment?.includes("id:44")) {
                            return new Date() <
                              new Date(
                                el?.validUntil?.toString().substring(0, 10)
                              ) ? (
                              <div>
                                {el?.validUntil?.toString().substring(0, 10)}
                              </div>
                            ) : (
                              <div style={{ color: "red" }}>Lejárt</div>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                  <div className={classes.countwrapper}>
                    {
                      <Typography
                        className={classes.itemData}
                        style={{
                          opacity: !isNaN(count) && count !== undefined && "1",
                          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                        }}
                        component="p"
                        variant="body1"
                      >
                        {isSchool ? `${count} Osztály` : `${count} Kép`}
                      </Typography>

                      //    <Typography className={classes.itemData} component="p" variant="body1">{`${size} GB`}</Typography>
                    }
                  </div>
                  {/*<Typography variant="body2" className={classes.editText}>Szerkesztéshez kattints</Typography>*/}
                </CardContent>
              </Tooltip>
            </CardActionArea>
          </Card>
        </Zoom>
      )}

      <Dialog
        open={alert}
        onClose={() => setAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.customDialogTitle}
          id="alert-dialog-title"
        >
          {"Biztosan törölni szeretnéd?"}
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.customButton}
            onClick={() => handleDelete()}
            color="primary"
          >
            Törlés
          </Button>
          <Button
            className={classes.customButton}
            onClick={() => setAlert(false)}
            color="primary"
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={recursiveAlert}
        onClose={() => setRecursiveAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classes.customDialogTitle}
          id="alert-dialog-title"
        >
          <div style={{ textAlign: "center" }}>Figyelmeztetés</div>
        </DialogTitle>
        <DialogContentText className={classes.checkbox}>
          Szeretném hogy a belső galériákat is megváltoztassa:{" "}
          <Checkbox
            color="primary"
            checked={inheritHeader}
            onChange={(e) => setinheritHeader(e.target.checked)}
          />
        </DialogContentText>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.customButton}
            onClick={() => switchChanged()}
            color="primary"
          >
            Folytatás
          </Button>
          <Button
            className={classes.customButton}
            onClick={() => setRecursiveAlert(false)}
            color="primary"
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupItem;
