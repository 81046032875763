/** @format */

import { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  Dialog,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import huLocale from "date-fns/locale/hu";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorIcon from "@mui/icons-material/Error";

import HeaderForm from "./HeaderForm/HeaderForm";
import ModifierForm from "../../ModifierForm/ModifierForm";
import { useHeaderLogic } from "./hooks/useHeaderLogic";
import { ButtonsWrapper, Title, useStyles, SelectWrapper } from "./styles";
import { useModificationLogic } from "../../ModifierForm/hooks/useModificationLogic";
import { useRef } from "react";
import { useEffect } from "react";

import { useTemplateApi } from "pages/PhotoAdmin/AdminMenu/Templates/hooks/useTemplateApi";
import { useModifierApi } from "pages/PhotoAdmin/AdminMenu/Templates/hooks/useModifierApi";
import { useGroupAPI } from "pages/PhotoAdmin/SelectShop/hooks/useGroupAPI";

const GroupDetails = ({
  shopId,
  currentGroup,
  belongsTo,
  toggle,
  parentHeader,
  isSchool,
  refresh,
  createModal,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { getCurrentHeader } = useHeaderLogic();
  const { getCurrentModifiers, clearModifiers } = useModificationLogic();
  const { putGroup, postGroup, getBody, loading } = useGroupAPI();
  const { getTemplates, templates, templatesLoading, getTemplate, template } =
    useTemplateApi();
  const { modifiers, getModifiers } = useModifierApi();

  const [header, setHeader] = useState({});
  const [headerDateChanged, setHeaderDateChanged] = useState(false);
  const [currentModifiers, setCurrentModifiers] = useState(
    getCurrentModifiers(currentGroup, modifiers)
  );

  const [headerSubmitted, setHeaderSubmitted] = useState(false);
  const [modifiersSubmitted, setModifiersSubmitted] = useState(false);

  const [disabled, setDisabled] = useState();
  const [check, setCheck] = useState();

  const buttonRef = useRef(null);
  const buttonRef2 = useRef(null);

  const [inherit, setInherit] = useState(false);
  const [usesUserTemplate, setUsesUserTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);

  const handleSubmit = async () => {
    await buttonRef2.current?.click();
    await buttonRef.current?.click();
    setCheck(!check);
  };

  const resetHeader = () => {
    refresh();
  };

  useEffect(() => {
    setInherit(header?.inheritModifiers);
    if (header.inheritModifiers) {
      setUsesUserTemplate(false);
    }
    if (createModal && !belongsTo && !header.inheritModifiers) {
      setUsesUserTemplate(true);
    }
  }, [selectedTemplate, header?.inheritModifiers]);

  useEffect(() => {
    const doSubmit = async () => {
      const validities = [];
      if (currentGroup?.productGroupModifierValidities.length > 0) {
        currentModifiers?.forEach((m) => {
          validities.push({
            id: currentGroup?.productGroupModifierValidities.find(
              (el) => el.modifierUriSegment === m.modifierUriSegment
            ).id,
            modifierUriSegment: m.modifierUriSegment,
            productGroupId: header.id,
            validUntil: m.validUntil,
          });
        });
      } else {
        currentModifiers?.forEach((m, i) =>
          validities.push({
            modifierUriSegment: m.modifierUriSegment,
            productGroupId: header.id,
            validUntil: m.validUntil,
          })
        );
      }
      setHeaderDateChanged(false);
      if (currentGroup) {
        toggle();
        await putGroup({
          body: getBody(
            {
              ...header,
              modifierTemplate:
                !usesUserTemplate || header?.inheritModifiers ? null : template,
              productGroupModifierValidities: validities,
            },
            usesUserTemplate || header?.inheritModifiers
              ? []
              : clearModifiers(currentModifiers)
          ),
        });
        refresh();
      } else {
        toggle();
        if (isSchool) {
          await postGroup({
            body: getBody(
              {
                ...header,
                belongsTo: belongsTo,
                accessKey: belongsTo?.accessKey,
                modifierTemplate: template,
                productGroupModifierValidities: validities,
              },
              !usesUserTemplate ? clearModifiers(currentModifiers) : []
            ),
          });
        } else {
          await postGroup({
            body: getBody(
              {
                ...header,
                belongsTo: belongsTo,
                accessKey: belongsTo?.accessKey,
                modifierTemplate:
                  !usesUserTemplate || header?.inheritModifiers
                    ? null
                    : template,
                productGroupModifierValidities: header?.inheritValidities
                  ? []
                  : validities,
              },
              usesUserTemplate || header?.inheritModifiers
                ? []
                : clearModifiers(currentModifiers)
            ),
          });
        }
        refresh();
      }
      setSelectedTemplate(-1);
      if (createModal && !belongsTo) {
        setHeader({ ...header, inheritModifiers: false });
      }
    };
    if (headerSubmitted) {
      doSubmit();
      setHeaderSubmitted(false);
      setModifiersSubmitted(false);
    }
  }, [headerSubmitted, modifiersSubmitted]);

  useEffect(() => {
    setHeader(getCurrentHeader(currentGroup, belongsTo, createModal));
    setCurrentModifiers(getCurrentModifiers(currentGroup, modifiers));
    if (createModal && !belongsTo) {
      setUsesUserTemplate(true);
    }
  }, [currentGroup, modifiers, belongsTo, createModal]);

  useEffect(() => {
    if (selectedTemplate !== -1) {
      getTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    getModifiers();
    getTemplates();
  }, []);

  useEffect(() => {
    if (currentGroup) {
      if (currentGroup || belongsTo) {
        const usesPreviouslyDefinedTemplate =
          currentGroup.modifierTemplate &&
          !currentGroup.modifierTemplate.technical;
        setUsesUserTemplate(usesPreviouslyDefinedTemplate);
        if (usesPreviouslyDefinedTemplate) {
          setSelectedTemplate(currentGroup.modifierTemplate.id);
        }
      }
    }
  }, [belongsTo, currentGroup]);

  return (
    <>
      {modifiers?.length > 0 && (
        <Paper align="center" component={Paper} className={classes.root}>
          <LocalizationProvider locale={huLocale} dateAdapter={AdapterDateFns}>
            <Title className={classes.title}>
              {!!belongsTo
                ? header?.name === "Csoportképek" ||
                  header?.name === "Group photos"
                  ? "Csoportképek"
                  : "Osztály"
                : "Iskola"}
            </Title>
            {(header?.id || currentGroup === null) && (
              <HeaderForm
                resetHeader={resetHeader}
                createModal={createModal}
                header={header}
                parentHeader={belongsTo}
                setHeader={setHeader}
                setHeaderSubmitted={setHeaderSubmitted}
                buttonRef={buttonRef}
                currentModifiers={currentModifiers}
                setCurrentModifiers={setCurrentModifiers}
                headerDateChanged={headerDateChanged}
                setHeaderDateChanged={setHeaderDateChanged}
              />
            )}
            {!inherit && (
              <FormControlLabel
                className={classes.formlabel}
                control={
                  <Checkbox
                    color="primary"
                    id="usesTemplate"
                    checked={usesUserTemplate}
                    value={usesUserTemplate}
                    onChange={(e) => setUsesUserTemplate(e.target.checked)}
                  />
                }
                labelPlacement="top"
                label="Ársablon használata"
              />
            )}
            {usesUserTemplate && (
              <>
                {templatesLoading ? (
                  <Typography variant="body1">Kérem várjon...</Typography>
                ) : (
                  <SelectWrapper>
                    {templates.length > 0 ? (
                      <Select
                        required
                        variant="outlined"
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        value={selectedTemplate}
                        fullWidth
                        style={{
                          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                        }}
                      >
                        <MenuItem
                          key={`default_template_option`}
                          value={-1}
                          disabled
                          style={{
                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                          }}
                        >
                          Kérem válasszon...
                        </MenuItem>
                        {templates?.map((template) => (
                          <MenuItem
                            key={`${template.id}_template`}
                            value={template.id}
                            style={{
                              fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                            }}
                          >
                            {template.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        variant="outlined"
                        defaultValue={-1}
                        fullWidth
                        style={{
                          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                        }}
                      >
                        <MenuItem
                          key={`no_templates`}
                          value={-1}
                          disabled
                          style={{
                            fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                          }}
                        >
                          Nincs megjeleníthető árazási sablon, hozzon létre
                          egyet!
                        </MenuItem>
                      </Select>
                    )}
                  </SelectWrapper>
                )}
              </>
            )}
            {!(
              currentModifiers?.length <= 0 ||
              usesUserTemplate ||
              inherit
            ) && (
              <ModifierForm
                shopId={shopId}
                modifiers={currentModifiers}
                setModifiers={setCurrentModifiers}
                setModifiersSubmitted={setModifiersSubmitted}
                inherit={inherit}
                buttonRef={buttonRef2}
                belongsTo={belongsTo}
                setDisabled={setDisabled}
              />
            )}

            <Typography
              style={{
                color: "black",
                opacity: disabled ? 1 : 0,
                transition: "opacity 300ms",
              }}
            >
              <ErrorIcon
                style={{
                  fontSize: "1rem",
                  margin: "auto 10px",
                  color: "red",
                }}
              />
              {
                "Az kiválasztott típusok árai nem lehetnek kevesebbek a minimális értéküknél"
              }
            </Typography>

            <ButtonsWrapper>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                onClick={toggle}
              >
                Mégse
              </Button>
              <Button
                disabled={
                  (selectedTemplate === -1 &&
                    !(
                      currentModifiers.find(
                        (el) => el.enabled === true && el.priority !== 1
                      )?.unitPrice?.grossAmount > 2
                    ) &&
                    !belongsTo &&
                    !currentGroup) ||
                  disabled ||
                  (usesUserTemplate && !template)
                }
                className={classes.confirmBtn}
                variant="contained"
                onClick={handleSubmit}
              >
                {currentGroup ? "Frissít" : "Létrehoz"}
              </Button>
            </ButtonsWrapper>
          </LocalizationProvider>
        </Paper>
      )}
      <Dialog
        open={loading === undefined || loading === false ? false : true}
        keepMounted
        maxWidth={"md"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper>
          <Typography>Kérem várjon...</Typography>
        </Paper>
      </Dialog>
    </>
  );
};

export default GroupDetails;
